module.exports = {
  title: "hongo",
  description: "홍고 블로그",
  author: "hongo",
  siteUrl: "https://blog.hongo.app",
  links: {
    github: "https://github.com/hgo641",
  },
  utterances: {
    repo: "hgo641/blog",
    type: "pathname",
  },
}
